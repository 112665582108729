<template>
  <div class="_bg-default _80w _100vh _100top">
    <div class="mt-2 pa-5">
      <notifications group="errors" class="mt-2" position="top center" />
      <h3 class="text-center mb-5">Create Portfolio</h3>
      <v-row>
        <v-col cols="12" md="2">Tipe</v-col>
        <v-col cols="12" md="10">
          <v-select
            solo
            hide-details="auto"
            item-text="state"
            dense
            item-value="abbr"
            :items="listType"
            v-model="portfolioType"
            label="Portfolio Category"
          ></v-select
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">Kategori Project</v-col>
        <v-col cols="12" md="10">
          <v-text-field
            label="Project Category"
            dense
            required
            hide-details="auto"
            solo
            v-model="categoryProject"
          ></v-text-field>
          <small>contoh: Couching, Counseling, etc</small>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">Service</v-col>
        <v-col cols="12" md="10">
          <v-select
            solo
            hide-details="auto"
            item-text="state"
            dense
            item-value="abbr"
            :items="listService"
            v-model="service"
            label="Service"
          ></v-select
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">Peran</v-col>
        <v-col cols="12" md="10">
          <v-text-field
            label="Peran"
            required
            dense
            solo
            hide-details="auto"
            v-model="position"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">Klien</v-col>
        <v-col cols="12" md="10">
          <v-text-field
            label="client"
            required
            dense
            solo
            hide-details="auto"
            v-model="client"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">Deskripsi</v-col>
        <v-col cols="12" md="10">
          <!-- <v-text-field label="client" required dense solo></v-text-field> -->
          <v-textarea
            solo
            label="Deskripsi"
            hide-details="auto"
            rows="2"
            v-model="description"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">Lokasi</v-col>
        <v-col cols="12" md="10">
          <v-text-field
            label="Location"
            required
            solo
            dense
            hide-details="auto"
            v-model="location"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">Date</v-col>
        <v-col cols="12" md="10"
          ><v-row>
            <v-col cols="12" md="6">
              <v-menu
                v-model="calendar1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_date"
                    label="Waktu Mulai"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    dense
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  @input="calendar1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="calendar2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_date"
                    label="Waktu Berakhir"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    dense
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_date"
                  @input="calendar2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col> </v-row
        ></v-col>
      </v-row>
      <footer class="_full-w d-flex justify-end mt-3">
        <v-btn rounded dark color="green" @click="postItem" v-if="!loading"
          ><v-icon class="mr-2">mdi-comment-check</v-icon>Save</v-btn
        >
        <v-btn rounded dark color="green" v-if="loading"
          ><v-progress-circular
            indeterminate
            size="25"
            class="mr-2"
          ></v-progress-circular
          >Loading</v-btn
        >
      </footer>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "createArticle",
  computed: {
    ...mapState({
      listCategory: state => state.library.category
    })
  },
  data() {
    return {
      listType: [
        { state: "Project Based", abbr: "Project Based" },
        { state: "Individual Service", abbr: "Individual Service" }
      ],
      listService: [
        { state: "Online", abbr: "Online" },
        { state: "Offline", abbr: "Offline" }
      ],
      calendar1: false,
      calendar2: false,
      service: "",
      description: "",
      portfolioType: "",
      categoryProject: "",
      client: "",
      start_date: null,
      end_date: null,
      location: "",
      position: "",
      loading: false
    };
  },
  mounted() {},
  methods: {
    postItem() {
      this.loading = true;
      let data = new FormData();
      data.append("layanan", this.service);
      data.append("kategori", this.categoryProject);
      data.append("klien", this.client);
      data.append("start_date", this.start_date);
      data.append("end_date", this.end_date);
      data.append("type", this.portfolioType);
      data.append("deskripsi", this.description);
      data.append("peran", this.position);
      data.append("location", this.location);
      this.$store
        .dispatch("psycholog/createPortfolio", data)
        .then(data => {
          this.loading = false;
          if (data.status == "failed") {
            let err = data.data.errors;
            console.log(err);
            for (let i in err) {
              this.alert("error", `${err[i][0]}`);
            }
          } else {
            this.service = "";
            this.description = "";
            this.position = "";
            this.categoryProject = "";
            this.client = "";
            this.start_date = null;
            this.end_date = null;
            this.portfolioType = "";
            this.location = "";
            this.$router.push("/psikolog/portfolio/list");
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000
            });
          }
          this.title = "";
          this.article = "";
          this.dp = null;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    },

    alert(type, title) {
      this.$notify({
        group: "errors",
        type: type,
        title: title
      });
    }
  }
};
</script>

<style scoped>
.temp-cover {
  width: 100%;
  height: 200px;
  border: 1px #000 dotted;
}
</style>
